import React from "react";
import "./settings.scss";
import { Form, Input, Modal, Spin } from "antd";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import { Cookies, useCookies } from "react-cookie";
import { useEffect } from "react";
import { renderRTLClass, getMonthString } from "../../utils/commonUtils";
import DashboardHeader from "../dashboard/DashboardHeader";
import { format } from "date-fns";

const SettingsComponent = (props) => {
  const {
    handleUnsubscribe,
    handleChangeDetails,
    details,
    handleUpdateDetails,
    handleChangeCountry,
    successPopup,
    setOpenModal,
    openModal,
    loading,
    unsubscribeResult,
    error,
    authenticate,
    subscriptionData,
    productList,
    authData,
  } = props;

  const cookies = new Cookies();
  const role = cookies.get("role");
  const countryOptions = countryList().getData();
  const { t } = useTranslation();

  const cur = subscriptionData?.data?.currency
    ? subscriptionData?.data?.currency
    : authenticate?.authenticateData?.data?.currency;
  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const findPrice = trialProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  // eslint-disable-next-line
  const [allCookies, setCookie, removeCookie] = useCookies(["token", "role"]);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", openModal);
    return () => document.body.classList.remove("no-scroll");
  }, [openModal]);

  const handleLogout = () => {
    removeCookie("token", { path: "/" });
    removeCookie("role", { path: "/" });
    const cookieKeys = Object.keys(allCookies);
    cookieKeys.forEach((key) => {
      if (!["currency", "lang", "langlogo"].includes(key)) {
        removeCookie(key, { path: "/" });
        removeCookie(key);
      }
    });
    const currentPath = window.location.pathname;
    const lang = allCookies.lang || "en";
    const newPath = `/${lang}`;
    if (currentPath !== newPath) {
      window.location.reload(newPath);
    }
  };

  return (
    <div className="dashboard-main-section">
      <div className="container">
        <DashboardHeader />
        <div className="account_dash_wrap">
          <div className="mt-50 wpb_content_account account_bg_wrap">
            <div className="setting-find-number">
              <label className="setting-billing-heading">
                <span className="dot"></span>
                <span>{t("ACCOUNT_INFORMATION")}</span>
              </label>
            </div>
            <div className="account_info_inner">
              <div className="wi-50 account_address">
                <strong>{t("EMAIL_ADDRESS")}</strong>
                <p>
                  {/* eslint-disable-next-line */}
                  <a>{authData.email}</a>
                </p>
              </div>
              <div className="wi-50 account_address">
                <strong>{t("CREATION_DATE")}</strong>
                <p>
                  {/* eslint-disable-next-line */}
                  <a>
                    <time className="" timestamp="2024-06-21T05:20:19.570Z">
                      {authData && authData?.createdAt && (
                        <>
                          <span className="txt-capitalize d-inline-block">
                            {t(getMonthString(authData?.createdAt))}
                          </span>{" "}
                          {format(authData?.createdAt, "dd").toString()}
                          {", "}
                          {format(authData?.createdAt, "yyyy").toString()}{" "}
                        </>
                      )}
                    </time>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Spin spinning={authenticate.isLoading || loading}>
          <div className="setting-find-number dashboard-box">
            <label className="setting-billing-heading">
              <span className="dot"></span>
              <span>{t("SETTINGS_BILLING_INFO_LABEL")}</span>
            </label>
            <Form>
              <div className="billing-form">
                <div className="input-fx">
                  <div className="f-name-input">
                    <label>{t("SETTINGS_FIRST_NAME_LABEL")}*</label>
                    <Input
                      placeholder={t("SETTINGS_FIRST_NAME_LABEL")}
                      name="first_name"
                      onChange={handleChangeDetails}
                      value={details.first_name}
                    />
                    {error.first_name && (
                      <span style={{ color: "red" }}>{error.first_name}</span>
                    )}
                  </div>
                  <div className="l-name-input">
                    <label>{t("SETTINGS_LAST_NAME_LABEL")}*</label>
                    <Input
                      placeholder={t("LAST_NAME_lABEL")}
                      name="last_name"
                      onChange={handleChangeDetails}
                      value={details.last_name}
                    />
                    {error.last_name && (
                      <span style={{ color: "red" }}>{error.last_name}</span>
                    )}
                  </div>
                </div>
                <div className="add-inp">
                  <label>{t("SETTINGS_ADDRESS_LABEL")}*</label>
                  <Input
                    placeholder="1 Main Street"
                    name="address"
                    onChange={handleChangeDetails}
                    value={details.address}
                  />
                  {error.address && (
                    <span style={{ color: "red" }}>{error.address}</span>
                  )}
                </div>
                <div className="input-fx">
                  <div className="f-name-input">
                    <label>{t("SETTINGS_COUNTRY_LABEL")}*</label>

                    <select
                      // showSearch
                      // style={{
                      //   width: 120,
                      // }}
                      name="country"
                      onChange={handleChangeCountry}
                      value={details.country}
                      // value={selectedCountry.value}
                      // options={countryOptions}
                    >
                      {countryOptions?.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                    {error.country && (
                      <span style={{ color: "red" }}>{error.country}</span>
                    )}
                  </div>
                  <div className="l-name-input">
                    <label>{t("SETTINGS_CITY_LABEL")}*</label>
                    <Input
                      placeholder="Barcelona"
                      name="city"
                      onChange={handleChangeDetails}
                      value={details.city}
                    />
                    {error.city && (
                      <span style={{ color: "red" }}>{error.city}</span>
                    )}
                  </div>
                </div>
                <div className="input-fx">
                  <div className="f-name-input">
                    <label>{t("SETTINGS_POSTAL_CODE_LABEL")}*</label>
                    <Input
                      placeholder="0123"
                      name="zipcode"
                      onChange={handleChangeDetails}
                      value={details.zipcode}
                    />
                    {error.zipcode && (
                      <span style={{ color: "red" }}>{error.zipcode}</span>
                    )}
                  </div>
                </div>
                <div className="save-billing-btn">
                  <button
                    onClick={handleUpdateDetails}
                    className="hl_cta_wrap"
                    disabled={loading}
                  >
                    {t("SETTINGS_SAVE_BUTTON")}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
        {authenticate.authenticateData?.data?.register_from === "HOME" ? (
          subscriptionData && (
            <div
              className="wpb_content_subs account_bg_wrap"
              id="subscription_list_account"
            >
              <div className="account_title">
                <h2>{t("PRICING_SUBSCRIPTION_TAG")}</h2>
              </div>
              <div className="subscriptions_table">
                <table>
                  <thead>
                    <tr>
                      <th>{t("PR_FREQUENCY")}</th>
                      <th>{t("PR_NEXT_INVOICE")}</th>
                      <th>{t("STATUS")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-title={t("PR_FREQUENCY")}>
                        <span className="tracking_config_wrapper">
                          <span className="tracking_environment">
                            {subscriptionData?.data?.frequency === "monthly"
                              ? t("MONTHLY")
                              : "N/A"}
                          </span>
                          {subscriptionData.data.trial && (
                            <button className="tracking_btn false">
                              {t("TRIAL")}
                            </button>
                          )}
                        </span>
                      </td>
                      <td data-title={t("PR_NEXT_INVOICE")}>
                        {subscriptionData?.data?.next_invoice && (
                          <>
                            <span>
                              <span className="txt-capitalize d-inline-block">
                                {t(
                                  getMonthString(
                                    subscriptionData?.data?.next_invoice
                                  )
                                )}
                              </span>{" "}
                              {format(
                                subscriptionData?.data?.next_invoice,
                                "dd"
                              )}{" "}
                              {t("DATE_FOR_LABEL")} {findPrice?.symbol}
                              {subscriptionData?.data?.amount}
                            </span>
                          </>
                        )}
                      </td>
                      <td data-title={t("STATUS")}>
                        <span
                          className={
                            subscriptionData.data.status === "active"
                              ? "green-bg"
                              : "red-bg"
                          }
                        >
                          {subscriptionData?.data?.status === "active"
                            ? t("PR_ACTIVE")
                            : subscriptionData?.data?.status === "canceled"
                            ? t("CANCELED")
                            : subscriptionData?.data?.status === "past_due"
                            ? t("PAST_DUE")
                            : "N/A"}
                        </span>
                      </td>
                      <td className="cancel_subscription">
                        <button
                          className="cancel_btn"
                          onClick={() => setOpenModal(true)}
                        >
                          {t("CANCEL_SUBSCRIPTION")}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )
        ) : (
          <div className="billing-footer-btn dashboard-box">
            <button
              className="logout-btn-wrap"
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              {t("LOGOUT")}
            </button>
            {role !== "admin" &&
              details?.email !== "solidgate@increast.com" && (
                <button onClick={() => setOpenModal(true)}>
                  {t("MENU_UNSUBSCRIBE")}
                </button>
              )}
          </div>
        )}
      </div>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={`${renderRTLClass()} home__unsubscribe_weapper`}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">
              {t("SETTING_UNSUBSCRIBE_TEXT")}
            </div>
            <div className="submit-btn-modal">
              <button onClick={handleUnsubscribe} disabled={loading}>
                {t("SETTING_UNSUBSCRIBE_SUBMIT_BUTTON")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={successPopup}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={`${renderRTLClass()} home__unsubscribe_weapper`}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">{unsubscribeResult?.message}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SettingsComponent;
